@import './colors.less';
@import './fonts.less';

@primary-color: @C1;

@map-bg: #303030;
// 背景色:侧边栏、灯组控制信息卡
@C2-background: fade(@C2, 90%);
// border-radius
@bdia-border-radius: 0.02rem;

// 节能
@bdia-energy: #20d474;
// 灯组开启颜色
@bdia-open: @primary-color;
// 灯组关闭颜色
@bdia-close: #929292;
// 灯组异常颜色
@bdia-error: @C5;

// 灯组控制
@bdia-lamp-sort-title-bg: fade(@C3, 10%);

// header
@header-height: 0.56rem;
@header-bg: @C2;
.header-font {
  .T1();
}
@header-color: @C3;
@header-icon-size: 0.32rem;
@header-icon-color: @C3;

// footer
@footer-height: 0.78rem;
@footer-bg: @C2;
.footer-font {
  .T2();
}
@footer-default-menu-bg: @C2-1;
@footer-default-menu-color: @C3;
@footer-acitve-menu-bg: @primary-color;
@footer-acitve-menu-color: @C2;

// dialog
@dialog-mask-bg: fade(@C2-5, 80%);
@dialog-border: 1px solid fade(@C2-3, 26%);
@dialog-bg: @C2-3;
@dialog-shadow: 0 0.04rem 0.1rem 0 fade(@C2-5, 44%);
// dialog中每一块title字体
.dialog-font-1 {
  .T1();
  font-weight: bold;
  color: @C2;
}
// dialog中每一项表单的key 字体样式
.dialog-font-2 {
  .T3();
  font-weight: 400;
  color: @C2;
}
// dialog中每一项表单的value 字体样式
.dialog-font-3 {
  .T3();
  font-weight: 400;
  color: @C4;
}

// confirm
@bdia-confirm-mask-bg: fade(@C2-5, 80%);
@bdia-confirm-bg: @C2-3;
@bdia-confirm-border: 1px solid @C4;
@bdia-confirm-color: @C2;
.bdia-confirm-title-font {
  .T2-2();
}
.bdia-confirm-content-font {
  .T3();
}

// input
@bdia-input-font-color: @C2;
@bdia-input-darkt-font-color: @C3;
@bdia-input-font-color-selection: @C2;
@bdia-input-bg: @C2-3;
@bdia-input-dark-bg: @C2-2;
@bdia-input-bg-disabled: @C3;
@bdia-input-border: 1px solid @C2;
@bdia-inputt-dark-border: 1px solid @C4;
@bdia-input-border-color-hover: @primary-color;
@bdia-input-border-color-focus: @C2;
@bdia-input-border-color-disabled: @C4;
.bdia-input-login-font {
  .T6();
}
@bdia-input-login-color: @C3;
@bdia-input-login-border-color: fade(@C3, 50%);
@bdia-input-login-border-color-focus: @C3;
@bdia-input-box-shadow-focus: 0 0 0 0.02rem @primary-color;

@bdia-input-height-small: 0.32rem;
.bdia-input-font-small {
  .T5();
}
@bdia-input-height-middle: 0.36rem;
.bdia-input-font-middle {
  .T4();
}

@bdia-input-height-large: 0.48rem;
.bdia-input-font-large {
  .T3();
}
// date-picker
@bdia-picker-week-color: fade(@C3, 70%);
@bdia-picker-hover-background: fade(@primary-color, 20%);

// button

@bdia-button-height-mini: 0.26rem;
.bdia-button-font-mini {
  .T5;
  color: @bdia-button-primary-color;
}
@bdia-button-height-small: 0.36rem;
.bdia-button-font-small {
  .T3;
  color: @bdia-button-primary-color;
}
@bdia-button-height-middle: 0.48rem;
.bdia-button-font-middle {
  .T2;
  color: @bdia-button-primary-color;
}
@bdia-button-height-large: 0.58rem;
.bdia-button-font-large {
  .T1;
  color: @bdia-button-primary-color;
}

@bdia-button-primary-color: @C2;
@bdia-button-primary-bg: @primary-color;
@bdia-button-primary-bg-hover: @yellow-1;
@bdia-button-primary-bg-active: @C1-3;
@bdia-button-primary-box-shadow: 0 0.04rem 0.08rem 0.01rem fade(@C1-3, 50%);

@bdia-button-gray-color: @C3;
@bdia-button-gray-bg: @C4;
@bdia-button-gray-bg-hover: @C4-1;
@bdia-button-gray-bg-active: @C2-2;
@bdia-button-gray-box-shadow: 0 0.04rem 0.08rem 0.01rem fade(@C4, 50%);

@bdia-button-default-color: @C3;
@bdia-button-default-bg: @C2;
@bdia-button-default-bg-border: @C4;
@bdia-button-default-bg-hover: @C1;
@bdia-button-default-bg-active: @C1;
@bdia-button-default-box-shadow: 'none';

// checkbox
@bdia-checkbox-label-color: @C3;
@bdia-checkbox-mark-color-checked: @C2; // 选中状态，对勾的颜色

// dropdown
@bdia-dropdown-btn-color: @C3;
@bdia-dropdown-btn-color-active: @primary-color;
@bdia-dropdown-btn-bg: @C2-2;
.bdia-dropdown-font-mini {
  .T5();
  color: @bdia-dropdown-btn-color;
}
.bdia-dropdown-font-small {
  .T4();
  color: @bdia-dropdown-btn-color;
}
@bdia-dropdown-border: 1px solid @C4;

@bdia-dropdown-menu-bg: @C2-background;
@bdia-dropdown-menu-hover-bg: fade(@C3, 10%);
// form
.bdia-form-light {
  .ant-form-item-label > label {
    color: rgba(255, 255, 255, 0.88);
  }
  .ant-input-textarea-show-count::after {
    color: rgba(255, 255, 255, 0.52);
    font-size: 0.14rem;
  }
}

// select
@bdia-select-dropdown-bg: fade(@C2, 80%);
@bdia-select-selected-bg: fade(@C3, 10%);

//table
@table-row-selected: #575754;
@table-row-hover: fade(@table-row-selected, 50%);

:export {
  bdiaEnergy: @bdia-energy;
  bdiaOpen: @primary-color; // 灯组开启颜色
  bdiaClose: @bdia-close; // 灯组关闭颜色
  bdiaError: @bdia-error; // 灯组异常颜色
  bdiaSuccess: #24eb82;
  bdiaStop: #ffc300; // 行李转盘紧急制动
}
