// 按照ui规范定义的变量名
// TODO

@T1-size: 0.22rem;
.T1 {
  font-size: @T1-size;
}

@T2-size: 0.2rem;
.T2 {
  font-size: @T2-size;
}

@T2-2-size: 0.2rem;
.T2-2 {
  font-size: @T2-2-size;
  font-weight: bold;
}

@T3-size: 0.18rem;
.T3 {
  font-size: @T3-size;
}

@T3-2-size: 0.18rem;
.T3-2 {
  font-size: @T3-2-size;
  font-weight: bold;
}

@T4-size: 0.16rem;
.T4 {
  font-size: @T4-size;
}

@T4-2-size: 0.16rem;
.T4-2 {
  font-size: @T4-2-size;
  font-weight: bold;
}


@T5-size: 0.14rem;
.T5 {
  font-size: @T5-size;
}

@T5-1-size: 0.14rem;
.T5-1 {
  font-size: @T5-1-size;
  font-weight: bold;
}

@T5-2-size: 0.12rem;
.T5-2 {
  font-size: @T5-2-size;
  font-weight: bold;
}

@T6-size: 0.4rem;
.T6 {
  font-size: @T6-size;
}

@T7-size: 0.36rem;
.T7 {
  font-size: @T7-size;
}

@T8-size: 0.26rem;
.T8 {
  font-size: @T8-size;
}

@T8-2-size: 0.26rem;
.T8-2 {
  font-size: @T8-2-size;
  font-weight: bold;
}

@T9-size: 0.16rem;
.T9 {
  font-size: @T9-size;
}

@T9-2-size: 0.14rem;
.T9-2 {
  font-size: @T9-2-size;
}